import React from 'react';
import { Layout } from '../../components/common';
import Header from '../../components/theme/Header';
import Footer from '../../components/theme/Footer';
import { FormattedMessage } from 'react-intl';
import SEO from '../../components/common/SEO';
import CarouselCustom from '../../components/common/IndexPage/carousel';
import TitleSection from '../../components/common/TitleSection';
import MainContentHolder from './styles';
import PricesPerfect from '../../components/common/Prices/pricesPerfect';

function importAll(r) {
  return r.keys().map(r);
}

// const imgArray = importAll(require.context('../../../static/Perfect', false, /\.(png|jpe?g|svg)$/));

import img1 from '../../../static/Perfect/01.jpg';
import img2 from '../../../static/Perfect/02.jpg';
import img3 from '../../../static/Perfect/03.jpg';
import img4 from '../../../static/Perfect/04.jpg';
import img5 from '../../../static/Perfect/05.jpg';
import img6 from '../../../static/Perfect/06.jpg';
import img7 from '../../../static/Perfect/07.jpg';
import img8 from '../../../static/Perfect/08.jpg';
import img9 from '../../../static/Perfect/09.jpg';
import img10 from '../../../static/Perfect/10.jpg';
import img11 from '../../../static/Perfect/11.jpg';
import img12 from '../../../static/Perfect/12.jpg';
import img13 from '../../../static/Perfect/13.jpg';
import img14 from '../../../static/Perfect/14.jpg';
import img15 from '../../../static/Perfect/15.jpg';
import img16 from '../../../static/Perfect/16.jpg';
import img17 from '../../../static/Perfect/17.jpg';
import img18 from '../../../static/Perfect/18.jpg';

let imgArray = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18
];

const apartmentPerfect = () => (
  <Layout>
    <React.Fragment>
      <Header />
      <SEO title="ApartmentPerfect" />
      <MainContentHolder className="main-content-holder">
        <TitleSection section="perfectHeader" />
        <div className="main-content-holder">
          <CarouselCustom imgArray={imgArray} />
        </div>
        <p className="apartment-description">
          <FormattedMessage id="perfectDescription" />
        </p>
        <ul className="apartmentItemList">
          <li>
            <FormattedMessage id="airConditioner" />
          </li>
          <li>
            <FormattedMessage id="washingMachine" />
          </li>
          <li>
            <FormattedMessage id="microwave" />
          </li>
          <li>
            <FormattedMessage id="fridge" />
          </li>
          <li>
            <FormattedMessage id="oven" />
          </li>
          <li>
            <FormattedMessage id="kettle" />
          </li>
          <li>
            <FormattedMessage id="hairdryer" />
          </li>
          <li>
            <FormattedMessage id="coffemachine" />
          </li>
          <li>
            <FormattedMessage id="iron" />
          </li>
          <li>
            <FormattedMessage id="tv" />
          </li>
          <li>
            <FormattedMessage id="wifi" />
          </li>
        </ul>

        <br />
        <div>
          <FormattedMessage id="condition13_1" />
          <FormattedMessage tagName="sup" id="condition13_2" />
          <FormattedMessage id="condition13_3" />
        </div>

        <h3>
          <FormattedMessage id="pricesPerfectText" />
        </h3>
        <p>
          <FormattedMessage id="pricesInformation" />
        </p>
        <div className="table-price-holder">
          <PricesPerfect />
        </div>
      </MainContentHolder>
      <Footer />
    </React.Fragment>
  </Layout>
);

export default apartmentPerfect;
